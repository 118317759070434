import { useState } from 'react'

import { Link } from 'react-router-dom'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import clsx from 'clsx'
// import toast from 'react-hot-toast'

import { DELETE_STORE, GET_USER, GET_USER_STORES, SEARCH_STORES, SET_STORE } from '../graphql'
import { InfoPopover } from '../../common/InfoPopover'
import { LoaderModal } from '../../common'
import Heading from './shared/heading'
import { STORES_LIMIT_DEFAULT } from '../../../config'
import { TextLinkStyle } from '../../../styles'

export function StoreFavs(): JSX.Element {
  const [userAddress, setUserAddress] = useState<string | null>(null)
  const [userStores, setUserStores] = useState<IStore[]>([])
  const [storeSearch, setStoreSearch] = useState<ISearchStores[]>([])

  const resetSearchStores = (userStores: IStore[]): void => {
    const tmpStores: ISearchStores[] = []
    storeSearch.forEach((store: any) => {
      store.isUserStore = userStores.some((userStore) => userStore._id === store._id) || false
      tmpStores.push(store)
    })
    setStoreSearch(tmpStores)
  }

  interface IStore {
    _id: string
    address: string
    bannerName: string
  }

  interface ISearchStores {
    _id: string
    address: string
    bannerName: string
    city: string
    isUserStore: boolean
  }

  const { loading: userLoading } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data === null || data.getUser.profile.address === null) return
      const addr = data.getUser.profile.address
      const street = addr.street as string
      const city = addr.city as string
      const stateProvince = addr.stateProvince as string
      const postalCode = addr.postalCode as string
      const country = addr.country as string
      const address = `${street}, ${city}, ${stateProvince} ${postalCode}, ${country}`
      setUserAddress(address)
      // TODO: we need to setup a solution to deal with the distanceUnit
      void getUserStores().then(() => {
        void searchStores({ variables: { input: { distanceUnit: 'km' } } })
      })
    },
  })

  const [getUserStores, { loading: storesLoading }] = useLazyQuery(GET_USER_STORES, {
    fetchPolicy: 'cache-and-network', // this policy is critical to get the other refetchQueries to work
    onCompleted: (data) => {
      if (data === null) return
      const tmpStores: IStore[] = []
      if (data.getUserStores.length > 0) {
        data.getUserStores.forEach((store: any) => {
          const { _id, address, bannerName } = store
          tmpStores.push({ _id, address, bannerName })
        })
      }
      setUserStores(tmpStores)
      resetSearchStores(tmpStores)
    },
    onError: (error) => {
      console.error('error: ', error.message)
    },
  })

  const [searchStores, { loading: searchLoading }] = useLazyQuery(SEARCH_STORES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data !== null && data.searchStores.length > 0) {
        const tmpStores: ISearchStores[] = []
        data.searchStores.forEach((store: any) => {
          const { _id, address, bannerName, city } = store
          const isUserStore = userStores.some((userStore) => userStore._id === _id)
          tmpStores.push({ _id, address, bannerName, city, isUserStore })
        })
        setStoreSearch(tmpStores)
      }
    },
  })

  const [setStore, { loading: setStoreLoading }] = useMutation(SET_STORE, {
    refetchQueries: [GET_USER_STORES],
  })

  const [deleteStore, { loading: deleteLoading }] = useMutation(DELETE_STORE, {
    refetchQueries: [GET_USER_STORES],
  })

  const handleSetStore = async (storeId: string): Promise<void> => {
    const storeExists = userStores.some((store) => store._id === storeId)
    if (storeExists) return

    const { data } = await setStore({ variables: { storeId } })
    const tmpStores: IStore[] = []
    data.setStore.forEach((store: any) => {
      const { _id, address, bannerName } = store
      tmpStores.push({ _id, address, bannerName })
    })
    setUserStores(tmpStores)
    resetSearchStores(tmpStores)
  }

  /*  if (networkStatus === NetworkStatus.refetch) {
    console.log('Refetching!', networkStatus)
  } */

  return (
    <>
      <div className='flex flex-col '>
        <Heading text='Current Address' />

        <div className='space-y-2 mb-6'>
          {userAddress !== null ? (
            <>
              <div className=''>{userAddress}</div>
              <div className=''>
                <Link to='/me/user-address' className={TextLinkStyle}>
                  Change your address
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className=''>Your address is required before selecting stores</div>
              <Link
                to='/me/user-address'
                className={`${TextLinkStyle} text-console.log();
                `}
              >
                Set your address
              </Link>
            </>
          )}
        </div>
      </div>

      <div className='flex flex-col xl:flex-row-reverse justify-between mt-8'>
        <div className='flex flex-col xl:w-1/2 xl:ml-8'>
          {userAddress !== null && (
            <div className='flex'>
              <Heading text='Selected Stores' />
              <InfoPopover>
                <div className='w-56'>You can select up to {STORES_LIMIT_DEFAULT} stores.</div>
              </InfoPopover>
            </div>
          )}

          <ul className='divide-y divide-slate-200 '>
            {userStores.map((store) => (
              <li key={store._id} className='flex flex-row py-2 hover:bg-slate-50 '>
                <div className='flex w-80 text-sm text-clip truncate overflow-hidden'>
                  {store.bannerName}
                  {' - '}
                  {store.address}
                </div>
                <div className='flex px-2 group'>
                  <button
                    className=''
                    onClick={() => void deleteStore({ variables: { storeId: store._id } })}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-5 h-5 stroke-primary-800 group-hover:fill-primary-200'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                      />
                    </svg>
                  </button>
                </div>
              </li>
            ))}
          </ul>

          {userStores.length > 0 && (
            <div className='flex mt-2'>
              <Link to='/' className={TextLinkStyle}>
                Go Shopping
              </Link>
            </div>
          )}
        </div>

        <div className='flex flex-col xl:w-1/2 mt-6 xl:mt-0'>
          {userAddress !== null && <Heading text='Stores in Your Area' />}

          <ul className='divide-y divide-slate-200'>
            {storeSearch.map((store) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={store._id}
                className={clsx(
                  'flex p-2 hover:bg-slate-50 cursor-pointer',
                  store.isUserStore && 'text-slate-400 cursor-text',
                )}
                onClick={() => {
                  handleSetStore(store._id)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleSetStore(store._id)
                  }
                }}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
              >
                <div className='text-sm text-left w-1/3 mr-3'>{store.bannerName}</div>
                <div className='text-sm text-left w-2/3 text-clip truncate overflow-hidden'>
                  {store.address}, {store.city}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <LoaderModal
        isOpen={deleteLoading || searchLoading || setStoreLoading || storesLoading || userLoading}
        message='Stand by fetching your info'
      />
    </>
  )
}

export default StoreFavs
