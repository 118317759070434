import { gql } from '@apollo/client'

export const AUTH_CODE_REQUEST = gql`
  mutation AuthCodeRequest($input: InputAuthCodeRequest!) {
    authCodeRequest(input: $input) {
      code
      message
      success
      session {
        userId
        sessionId
        email
        firstName
        lastName
        haveStoreIds
        roleBits
        ttl
      }
    }
  }
`

export const AUTH_EMAIL_PASSWORD = gql`
  mutation AuthEmailPassword($input: InputAuthEmailPassword!) {
    authEmailPassword(input: $input) {
      code
      message
      success
      session {
        userId
        sessionId
        email
        firstName
        lastName
        haveStoreIds
        roleBits
        ttl
      }
    }
  }
`

export const PASSWORD_RESET_REQUEST = gql`
  mutation PasswordResetRequest($email: String!) {
    passwordResetRequest(email: $email) {
      code
      message
      success
      existingAuthType
    }
  }
`

export const PASSWORD_RESET_CONFIRM = gql`
  mutation PasswordResetConfirm($input: InputNoncePassword!) {
    passwordResetConfirm(input: $input) {
      code
      message
      success
      session {
        sessionId
        userId
        email
        firstName
        lastName
        haveStoreIds
        roleBits
        ttl
      }
    }
  }
`
