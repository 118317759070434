import React, { useEffect, useState } from 'react'

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'
import clsx from 'clsx'
import isStrongPassword from 'validator/lib/isStrongPassword'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'

import { AUTH_EMAIL_PASSWORD } from '../../graphql'
import { ErrorModal } from '../../../common/ErrorModal'
import { HeaderCentered as Header } from '../../../layout/index'
import { Container, ErrMsg, Title } from '../Common'
import { InputStyle, SubmitButStyle, TextLinkStyle } from '../../../../styles/common'
import { setUserSession } from '../../authSlice'
import { getShopListId } from '../../../products/shopListSlice'
import { useAppDispatch } from '../../../../config'

interface IFormErrors {
  email: string | false
  password: string | false
}

const fldErrsDefaults: IFormErrors = {
  email: false,
  password: false,
}

const FormData = z.object({
  email: z.string().email(),
  password: z
    .string()
    .trim()
    .refine((val) => isStrongPassword(val), {
      message: 'Invalid password',
    }),
})

// NOTE: for naming, see this article: https://ux.stackexchange.com/questions/1080/using-sign-in-vs-using-log-in
export function EmailSigninForm(): JSX.Element {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [fieldErrs, setFieldErr] = useState<IFormErrors>(fldErrsDefaults)
  const [showPasswd, setShowPasswd] = useState<boolean>(false)
  const [resultError, setResultError] = useState<string | null>(null)
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false)

  function closeErrorModal(): void {
    setIsErrorOpen(false)
  }

  function openErrorModal(): void {
    setIsErrorOpen(true)
  }

  const [authEmailPassword, { data, loading }] = useMutation(AUTH_EMAIL_PASSWORD)

  const from = location.state?.from?.pathname || '/'

  const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()

    toast.dismiss()
    setFieldErr(fldErrsDefaults)

    const target = e.target as typeof e.target & {
      email: { value: string }
      password: { value: string }
      reset: () => null
    }

    const result = FormData.safeParse({
      email: target.email.value,
      password: target.password.value,
    })

    if (!result.success) {
      const errors = result.error.format() as any
      for (const key in errors) {
        if (key === '_errors') continue
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
          setFieldErr((fieldErrs) => ({
            ...fieldErrs,
            [key]: errors[key]._errors.join(', '),
          }))
        }
      }
      return
    }
    // FIXME: prefer this way of handling errors
    /* 
    const errs: Record<string, string> = {}
    try {
      FormSchema.parse({ password })
    } catch (error) {
      if (error instanceof ZodError) {
        error.errors.forEach(err => {
          errs[err.path[0]] = err.message
        })
        // In this case, we set the error message for the 'password' field
        setErrorMsg(errs.password || '')
        setDisabled(false)
      } else {
        // Handle other possible errors
        console.error('An unexpected error occurred:', error)
      }
    }
    */

    void authEmailPassword({
      variables: {
        input: {
          email: result.data.email,
          password: result.data.password,
        },
      },
    })

    // target.reset()
  }

  useEffect(() => {
    if (data !== undefined) {
      if (data.authEmailPassword.success === true) {
        const sess = data.authEmailPassword.session
        dispatch(setUserSession(sess))
        // check if user has a shopList
        dispatch(getShopListId())
        toast.success(
          <div className='text-slate-600'>
            Welcome back <span className='font-bold'>{sess.firstName}</span>! 👋
          </div>,
        )
        navigate(from, { replace: true })
      } else {
        setResultError('The email or password you entered is incorrect. Please try again.')
        openErrorModal()
      }
    }
  }, [data, dispatch, navigate, from])

  return (
    <div className='bg-white h-screen md:bgimg'>
      <Header />
      <Container>
        <Title title='Sign in to Savvie' />
        <form onSubmit={handleSubmit}>
          <fieldset className='grid grid-cols-1 gap-x-6 px-1 mt-4 gap-y-6'>
            <div className=''>
              <input
                autoFocus
                autoComplete='off'
                type='email'
                name='email'
                id='email'
                className={clsx(InputStyle, fieldErrs.email !== false && 'border-red-600')}
                placeholder='Email'
                required
                tabIndex={0}
                // defaultValue='rond@webbtech.net'
              />
              {fieldErrs.email !== false && <ErrMsg errStr={fieldErrs.email} />}
            </div>

            <div className=''>
              <div className='flex flex-grow'>
                <input
                  type={showPasswd ? 'text' : 'password'}
                  name='password'
                  id='password'
                  autoComplete='current-password'
                  className={clsx(
                    'block w-full rounded-none rounded-l-md text-sm border-slate-400 text-slate-900 shadow-sm shadow-slate-400 focus:border-primary-600 focus:ring-primary-600',
                    fieldErrs.password !== false && 'border-red-600',
                  )}
                  placeholder='Password'
                  required
                  tabIndex={0}
                  // defaultValue='MyPassword1!'
                />
                <button
                  type='button'
                  onClick={() => setShowPasswd(!showPasswd)}
                  className={clsx(
                    'relative -ml-px inline-flex items-center rounded-r-md border border-slate-400 bg-slate-100 px-2.5 py-1 text-sm font-medium text-slate-900 shadow-sm shadow-slate-400 hover:bg-slate-200 transition duration-150 ease-in-out',
                    fieldErrs.password !== false && 'border-red-600',
                  )}
                  tabIndex={-1}
                >
                  {showPasswd ? (
                    <EyeSlashIcon className='h-5 w-5 text-primary-600' aria-hidden='true' />
                  ) : (
                    <EyeIcon className='h-5 w-5 text-slate-500' aria-hidden='true' />
                  )}
                </button>
              </div>
              {fieldErrs.password !== false && <ErrMsg errStr={fieldErrs.password} />}
            </div>

            <div className='flex justify-center'>
              <button
                className={`${SubmitButStyle} w-36 disabled:opacity-75`}
                disabled={loading}
                tabIndex={0}
              >
                {loading ? 'Stand by...' : 'Sign In'}
              </button>
            </div>
          </fieldset>
        </form>
        <Link
          to='/forgot-password'
          className={clsx(TextLinkStyle, 'flex mt-6 text-sm justify-center w-fit mx-auto')}
        >
          Forgot your password?
        </Link>
        <div className='flex flex-col items-center text-center mt-3 text-sm text-slate-700'>
          Don&apos;t have an account?
          <a href='https://register.savvie.app' className={clsx(TextLinkStyle, 'w-fit')}>
            Register Now!
          </a>
        </div>
      </Container>
      <ErrorModal isOpen={isErrorOpen} closeFunc={closeErrorModal} message={resultError} />
    </div>
  )
}

export default EmailSigninForm
