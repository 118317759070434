import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { useMutation } from '@apollo/client'
import { z } from 'zod'
import clsx from 'clsx'
import isStrongPassword from 'validator/lib/isStrongPassword'

import { Container, ErrMsg, Title } from './Common'
import { ErrorModal } from '../../common/ErrorModal'
import { HeaderCentered as Header } from '../../layout/index'
import { InputStyle, SubmitButStyle } from '../../../styles/common'
import { PasswdChecklist } from './PasswdChecklist'
import { PASSWORD_RESET_CONFIRM } from '../graphql'
import { setUserSession } from '../authSlice'
import { useAppDispatch } from '../../../config'

interface IFormErrors {
  password: string | false
  repeatPassword: string | false
}

const fldErrsDefaults: IFormErrors = {
  password: false,
  repeatPassword: false,
}

const FormData = z
  .object({
    password: z
      .string()
      .trim()
      .refine((val) => isStrongPassword(val), {
        message: 'Invalid password',
      }),
    repeatPassword: z.string().trim(),
  })
  .refine((data) => data.repeatPassword === data.password, {
    message: 'Passwords must match',
    path: ['repeatPassword'],
  })

function useSearchQuery(): URLSearchParams {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const INVALID_RESET_PASSWORD_REQUEST =
  'Invalid reset password request. Check your link and try again.'

export function ResetPassword(): JSX.Element {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const query = useSearchQuery()

  const [showPasswd, setShowPasswd] = useState<boolean>(false)
  const [fieldErrs, setFieldErr] = useState<IFormErrors>(fldErrsDefaults)
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false)
  const [resultError, setResultError] = useState<string | null>(null)
  const [password, setPassword] = useState<string>('')

  function closeErrorModal(): void {
    setIsErrorOpen(false)
  }

  function openErrorModal(): void {
    setIsErrorOpen(true)
  }

  const [submit, { data, loading }] = useMutation(PASSWORD_RESET_CONFIRM)

  const handlePasswordChange = (e: FormEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement
    setPassword(target.value)
  }

  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault()
    const nonce = query.get('nonce')

    setFieldErr(fldErrsDefaults)

    if (nonce === null) {
      setResultError(INVALID_RESET_PASSWORD_REQUEST)
      openErrorModal()
      return
    }

    const target = e.target as typeof e.target & {
      password: { value: string }
      repeatPassword: { value: string }
      reset: () => null
    }

    const result = FormData.safeParse({
      password: target.password.value,
      repeatPassword: target.repeatPassword.value,
    })

    if (!result.success) {
      const errors = result.error.format() as any
      for (const key in errors) {
        if (key === '_errors') continue
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
          setFieldErr((fieldErrs) => ({
            ...fieldErrs,
            [key]: errors[key]._errors.join(', '),
          }))
        }
      }
      return
    }

    void submit({
      variables: {
        input: {
          nonce: nonce,
          password: target.password.value,
        },
      },
    })
    target.reset()
    setPassword('')
  }

  useEffect(() => {
    if (data !== undefined) {
      if (data.passwordResetConfirm.success === true) {
        dispatch(setUserSession(data.passwordResetConfirm.session))
        navigate('/')
      }
      if (data.passwordResetConfirm.success === false) {
        openErrorModal()
        setResultError(INVALID_RESET_PASSWORD_REQUEST)
      }
    }
  }, [data, dispatch, navigate])

  useEffect(() => {
    const nonce = query.get('nonce')
    if (nonce === null) {
      setResultError(INVALID_RESET_PASSWORD_REQUEST)
      openErrorModal()
    }
  }, [query])

  return (
    <div className='bg-white h-screen md:bgimg'>
      <Header />
      <Container>
        <Title title='Reset Password' />
        <form className='mt-2' onSubmit={handleSubmit}>
          <fieldset>
            <div className='grid grid-cols-1 gap-x-6 px-1 mt-4 gap-y-6'>
              <div className='grid grid-cols-1'>
                <div className='flex'>
                  <div className='flex flex-grow'>
                    <input
                      type={showPasswd ? 'text' : 'password'}
                      name='password'
                      id='password'
                      autoComplete='new-password'
                      autoFocus
                      tabIndex={0}
                      className={clsx(
                        'block w-full rounded-none rounded-l-md text-sm border-slate-400 text-slate-900 shadow-sm shadow-slate-400 focus:border-primary-600 focus:ring-primary-600',
                        fieldErrs.password !== false && 'border-red-600',
                      )}
                      onChange={handlePasswordChange}
                      placeholder='Password'
                      required
                      value={password}
                      // defaultValue='MyPassword1!'
                    />
                    <button
                      type='button'
                      onClick={() => setShowPasswd(!showPasswd)}
                      tabIndex={-1}
                      className={clsx(
                        'relative -ml-px inline-flex items-center rounded-r-md border border-slate-400 bg-slate-100 px-2.5 py-1 text-sm font-medium text-slate-900 shadow-sm shadow-slate-400 hover:bg-slate-200 transition duration-150 ease-in-out',
                        fieldErrs.password !== false && 'border-red-600',
                      )}
                    >
                      {showPasswd ? (
                        <EyeSlashIcon className='h-5 w-5 text-primary-600' aria-hidden='true' />
                      ) : (
                        <EyeIcon className='h-5 w-5 text-slate-500' aria-hidden='true' />
                      )}
                    </button>
                  </div>
                </div>
                {fieldErrs.password !== false && <ErrMsg errStr={fieldErrs.password} />}
                <div
                  className={`${
                    password.length > 0 ? 'opacity-100' : 'opacity-0 h-0'
                  } transition-opacity ease-in-out duration-150`}
                >
                  <PasswdChecklist value={password} />
                </div>
              </div>

              <div className=''>
                <input
                  type={showPasswd ? 'text' : 'password'}
                  name='repeatPassword'
                  id='repeatPassword'
                  tabIndex={0}
                  className={clsx(
                    InputStyle,
                    fieldErrs.repeatPassword !== false && 'border-red-600',
                  )}
                  required
                  placeholder='Repeat Password'
                  // defaultValue='MyPassword1!'
                />
                {fieldErrs.repeatPassword !== false && <ErrMsg errStr={fieldErrs.repeatPassword} />}
              </div>

              <div className='flex justify-center'>
                <button
                  className={`${SubmitButStyle} w-36 disabled:opacity-75`}
                  disabled={loading}
                  type='submit'
                  tabIndex={0}
                >
                  {loading ? 'Stand by...' : 'Submit'}
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </Container>
      <ErrorModal isOpen={isErrorOpen} closeFunc={closeErrorModal} message={resultError} />
    </div>
  )
}

export default ResetPassword
