import { useState } from 'react'

import { useApolloClient } from '@apollo/client'

import { DeleteAccountConfirmModal } from './DeleteAccountConfirmModal'
import { GET_USER_ACCOUNT_INFO } from '../../graphql/queries'
import { TextLinkStyle } from '../../../../styles/common'

export function Details(): JSX.Element {
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false)
  const client = useApolloClient()

  const data = client.readQuery({ query: GET_USER_ACCOUNT_INFO })
  if (data == null) return <></>

  const cellLabelStyle = 'whitespace-nowrap py-1 mb-2 text-sm text-slate-900'
  const cellValueStyle = 'whitespace-nowrap px-3 py-1 text-sm text-slate-700'
  const displayBool = (value: boolean): string => (value === true ? 'Yes' : 'No')

  return (
    <>
      <div className='flex flex-col'>
        <div className='text-base font-semibold leading-6 text-slate-900'>Subscription Details</div>
        <table className='table-auto w-full mt-4 max-w-md'>
          <tbody className='divide-y divide-slate-300'>
            <tr>
              <td className={cellLabelStyle}>Account Name</td>
              <td className={cellValueStyle}>
                {data.getUser.firstName} {data.getUser.lastName}
              </td>
            </tr>
            <tr className=''>
              <td className={cellLabelStyle}>Email</td>
              <td className={cellValueStyle}>{data.getUser.email}</td>
            </tr>
            <tr className=''>
              <td className={cellLabelStyle}>Plan</td>
              <td className={cellValueStyle}>{data.getUser.subscription.planName}</td>
            </tr>
            <tr className=''>
              <td className={cellLabelStyle}>Active</td>
              <td className={cellValueStyle}>{displayBool(data.getUser.subscription.active)}</td>
            </tr>
            <tr className=''>
              <td className={cellLabelStyle}>Status</td>
              <td className={cellValueStyle}>{data.getUser.subscription.status}</td>
            </tr>
          </tbody>
        </table>
        <div className='border-t-2 border-slate-500 pt-4 mt-8'>
          <button onClick={() => setIsOpenConfirm(!isOpenConfirm)} className={TextLinkStyle}>
            Delete my account
          </button>
        </div>
      </div>
      <DeleteAccountConfirmModal isOpen={isOpenConfirm} setIsOpen={setIsOpenConfirm} />
    </>
  )
}

export default Details
