import { isDevMode, isProdMode, isStageMode } from '../utils/envMode'

export const APPLE_CLIENT_ID = 'app.savvie.auth'

export const APP_PROD_URI = 'https://savvie.app'
export const APP_STAGE_URI = 'https://stage.savvie.app'

export const AWS_REGION = 'us-east-1'
export const AWS_SAVVIE_CDN_BUCKET = 'savvie-cdn'
export const COOKIE_ACCEPTED_BETA_NOTICE = 'Savvie_accepted_beta_notice'

export const STORES_LIMIT_DEFAULT = 6
export const SHOP_LIST_ID_KEY = 'ShopListId'
export const SUPPORT_EMAIL = 'Support <support@buysavvie.com>'

export const USER_SESSION_KEY = 'UserSess'
export const USER_SESSION_ID_KEY = 'UserSessId'

const GRAPHQL_ENDPOINT_PROD = 'https://prod--savvie-dedicated.apollographos.net/graphql'
const GRAPHQL_ENDPOINT_STAGE = 'https://stage--savvie-graph.apollographos.net/graphql'
// const GRAPHQL_ENDPOINT_MOBILE = 'https://mobile--savvie-graph.apollographos.net/graphql'

let GRAPHQL_ENDPOINT = ''

// As more items may require a different staging mode, we simply update this function
;(() => {
  if (isDevMode()) {
    GRAPHQL_ENDPOINT = GRAPHQL_ENDPOINT_STAGE
    // GRAPHQL_ENDPOINT = GRAPHQL_ENDPOINT_MOBILE
    // GRAPHQL_ENDPOINT = GRAPHQL_ENDPOINT_PROD
  } else if (isProdMode()) {
    GRAPHQL_ENDPOINT = GRAPHQL_ENDPOINT_PROD
  } else if (isStageMode()) {
    GRAPHQL_ENDPOINT = GRAPHQL_ENDPOINT_STAGE
    // GRAPHQL_ENDPOINT = GRAPHQL_ENDPOINT_MOBILE
  }
})()

export default { GRAPHQL_ENDPOINT } // TODO: Remove this line after testing whether required
export { GRAPHQL_ENDPOINT }
